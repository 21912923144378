<template>
  <div>
    <navbar @asideShow="asideFlagFun" ></navbar>
    <div class="main_center">
      <div class="back" @click="back">
        <van-image width="9px" height="15px" :src="require('@/assets/cls/arrow-l.png')" />
        <span>{{$t("withdraw.return")}}</span>
      </div>
      <div class="record">
            <div class="record_top">
              <div>时间</div>
              <div>用户ID</div>
              <div>到账数量($)</div>
            </div>
            <van-pull-refresh style="height:561px;overflow: scroll;" :success-text="$t('activeRecord.refresh')" v-model="refreshing" @refresh="onRefresh">
              <van-list
                v-model="loading"
                :finished="finished"
                :finished-text="$t('activeRecord.NoMore')"
                @load="onLoad"
                class="list"
                :immediate-check="false"
                :offset="100"
              >
              <div class="record_center" v-for="(item,index) in list" :key="index">
                  <div style="white-space:nowrap;">{{item.created_at}}</div>
                  <div>{{item.to_user_id}}</div>
                  <div>{{item.amount}}</div>
              </div>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
    <asidePopup v-if="asideFlag" @close="noShow" :shows='asideFlag'></asidePopup>

  </div>
</template>

<script>
import asidePopup from "@/components/common/asidePopup";

export default {
  components:{asidePopup},
  data() {
    return {
      asideFlag: false,
      loading: false, //加载
      finished: false, //加载结束
      refreshing: false, //下拉刷新
      list:[],
      page:1,
      pageSize:15,
    };
  },
  created(){
    this.getInfo()
  },
  methods:{
    //上拉加载
    onLoad(){
      const load = true
      this.page++
      this.getInfo(load)
    },
    //下拉刷新
    onRefresh(){
      this.finished = false;
      this.page = 1
      this.getInfo()
    },
    getInfo(load = false){
      this.$axios.get(this.$api.transferList,{
        page:this.page,
        pageSize:this.pageSize
      }).then(res => {
        if(load){
          this.list = [...this.list,...res.data.list]
          this.loading = false;
          if(!res.data.list.length){
            this.finished = true
          }
        }else{
          this.list = res.data.list
          this.refreshing = false
        }
      })
    },
    noShow(){
      this.asideFlag = false
    },
    asideFlagFun(){
      this.asideFlag = true
    },
      back() {
      this.$router.back();
    },
  }
};
</script>

<style lang="scss" scoped>
.back {
  margin: 13px 0 28px;
  display: flex;
  align-items: center;
  span {
    font-size: 15px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    margin-left: 6px;
  }
}
.main_center {
  width: 100%;
  padding: 5px 10px;
  .title {
    font-size: 15px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
  }
  .record {
    width: 100%;
    height: 621px;
    border: 1px solid #000;
    border-radius: 15px;
    margin-top: 12px;
    padding: 10px 5px;
    .record_top {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #eaeaea;
      font-size: 10px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #999999;
      div{
        //   width: 94px;
        flex: 0.3;
          text-align: left;
      }
      div:nth-of-type(2){
          text-align: center;
      }
      div:nth-of-type(3){
          text-align: right;
      }
    }
    .record_center {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #eaeaea;
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #000;
      div{
        //   width: 94px;
        flex: 0.3;
          text-align: left;
      }
      div:nth-of-type(2){
          text-align: center;
      }
      div:nth-of-type(3){
          text-align: right;
      }
    }
  }
}
</style>